import { calcAxisSort } from 'src/redux/reducers/takingAssessment'

export const parseProcessName = (process) => {
    if (process === 'Maturity Stage') {
        return 'Market Maturity Stage'
    } else if (process === 'Opportunity Discovery' || process === 'Discovery') {
        return 'Opportunity Discovery'
    } else if (process === 'Decisions') {
        return 'Decision Making'
    } else if (process === 'Capital and Growth') {
        return 'Capital and Business Growth'
    } else if (process === 'Customer Support') {
        return 'Support'
    }
    else {
        return process
    }
}

const parseCategory = (category) => {

    if (category === 'COMMERCIALIZATION: Category of Processes' || category === 'COMMERCIALIZATION') {
        return 'Commercialization'
    } else {
        return category
    }
}

const assignProcessIds = (categories) => {
    // Set the expected number of maturity levels per process.
    // Even if the current data only has 4 levels, we reserve 7 for future use.
    const totalExpectedLevels = 6;
    let processIdCounter = 1; // Global counter that will not reset between categories

    return categories.map(category => {
        const updatedProcesses = category.processes.map(process => {
            // Clone and sort the existing maturityLevels by the 'level' property
            const sortedLevels = [...process.maturityLevels].sort((a, b) => a.level - b.level);

            // Create an array with exactly totalExpectedLevels items.
            // If a level doesn't exist in the original data, fill with a placeholder.
            const updatedMaturityLevels = Array.from({ length: totalExpectedLevels }, (_, i) => {
                const existingLevel = sortedLevels[i];
                return {
                    processId: processIdCounter++,
                    // Use the existing level if available; otherwise, supply a default placeholder.
                    ...(existingLevel || { level: i, description: '', placeholder: true })
                };
            });

            return { ...process, maturityLevels: updatedMaturityLevels };
        });
        return { ...category, processes: updatedProcesses };
    });
};


function getProcessId(categories, processName, selectionValue) {
    // Loop through each category.
    for (const category of categories) {
        // Loop through each process in the category.
        for (const process of category.processes) {

            // Match the process by its processName.
            if (process.menuName === processName) {

                // Find the maturity level that matches the provided selection.
                // Note: This assumes that selectionValue corresponds to the maturity level’s "level" property.
                const matchingLevel = process.maturityLevels.find(m => m.level === selectionValue);
                if (matchingLevel) {
                    return matchingLevel.processId;
                }
            }
        }
    }
    return null; // Not found.
}

export const transformData = (data) =>
    data.map((item) => {
        const updatedData = assignProcessIds(item.evaluation);
        const evaluation = transformEvaluation(updatedData)
        const selections = evaluation.map((evalItem) => evalItem.selection)
        const average = calculateAverageSelection(selections)
        const quad = calcAxisSort(item)
        const mostVulnerableProcess = findMostVulnerableProcess(item)
        const processId = getProcessId(updatedData, mostVulnerableProcess?.processName, mostVulnerableProcess?.selection);

        return {
            accessCode: item.accessCode,
            dateAssessed: item.dateAssessed.seconds,
            ...evaluation,
            vulnerable: quad[1][3],
            average,
            mostVulnerableProcess: mostVulnerableProcess?.processName || 'Test Out',
            mostVulnerableProcessNotes: mostVulnerableProcess?.notes || 'N/A',
            mostVulnerableProcessId: mostVulnerableProcess?.processName ? processId : '193'
        }
    })

const transformEvaluation = (evaluation) =>
    evaluation.flatMap(({ category, processes, average }) =>
        processes.flatMap(({ menuName, selection }) => ({
            category: parseCategory(category),
            processesName: parseProcessName(menuName),
            selection,
            averageSection: average,
        }))
    )

// Function to calculate average of selections
const calculateAverageSelection = (selections) =>
    selections.reduce((acc, val) => acc + val, 0) / selections.length


const generateTableData = (data) => {
    const flattenedData = data.flatMap(item =>
        item.evaluation.map(evaluation => ({
            category: evaluation.category,
            process: evaluation.processesName,
        }))
    )

    const uniqueProcesses = [...new Map(flattenedData.map(item => [item.process, item])).values()]

    const dataWithEvaluations = uniqueProcesses.map(processItem => {
        const evaluations = data.reduce((acc, currentItem) => {
            const evaluation = currentItem.evaluation.find(e => e.processesName === processItem.process)
            if (evaluation) acc[currentItem.accessCode] = evaluation.selection
            return acc
        }, {})

        return { ...processItem, ...evaluations }
    })

    dataWithEvaluations.forEach(item => {
        const values = Object.values(item).filter(val => typeof val === 'number')
        const sum = values.reduce((acc, curr) => acc + curr, 0)
        item.average = values.length > 0 ? sum / values.length : 0
    })

    return dataWithEvaluations
}


export const findMostVulnerableProcess = (item) => {

    let inf = calcAxisSort(item)

    // convert axis sequence to a flat array of strings
    const stringValues = inf[0].flat()
        .filter(item => typeof item === 'string')
        .reverse()

    // Create an object mapping each string to an index (starting at 1)
    const axisInfluence = stringValues.reduce((acc, curr, idx) => {
        acc[curr] = idx + 1;
        return acc;
    }, {});

    // Transform evaluation data into a structured format
    const evaluations = item.evaluation.map(({ category, axis, processes }) => ({
        axis,
        processes: processes.map(({ rank, selection, notes, inhibitor, menuName }) => ({
            rank,
            selection,
            notes,
            inhibitor,
            processName: parseProcessName(menuName) // Ensure process name is parsed correctly,
        }))
    }));


    const allSelectionsAreThree = evaluations.every(axis =>
        axis.processes.every(process => process.selection === 3)
      );

    const targetAxis = Object.keys(axisInfluence).find(axis => axisInfluence[axis] === 1); // targetAxis is "East"

    const result = evaluations.filter(item => item.axis === targetAxis)[0]?.processes;

    result.sort((a, b) => {
        if (a.selection === b.selection) {
            return a.rank < b.rank ? -1 : 1
        } else {
            return a.selection < b.selection ? -1 : 1
        }
    })

    return allSelectionsAreThree ? { processName: 'totalTestOut' } : result[0] || null; // Return the most vulnerable process based on the lowest selection value

}

export const generateExportData = (inputArray, basicData) => {
    // Define headers
    const headers = ["Data Variables"];
    inputArray.forEach((_, index) => headers.push(`Assessment ${index + 1}`));

    // Initialize row structure
    const csvRows = [];

    // Function to add data rows
    const addRow = (values) => {
        csvRows.push(values);
    };

    // Helper function to escape CSV fields
    const escapeCSV = (value) => {
        // Convert non-string values to strings
        const strValue = value !== undefined && value !== null ? value.toString() : "";
        // If the value contains a comma, a double quote, or a newline, wrap it in quotes and escape double quotes
        if (strValue.includes(",") || strValue.includes('"') || strValue.includes("\n")) {
            return `"${strValue.replace(/"/g, '""')}"`;
        }
        return strValue;
    };

    // Process metadata only once
    addRow(["Assessment Date", ...inputArray.map((obj) => new Date(obj.dateAssessed * 1000).toLocaleDateString())]);
    addRow(["Access Code Last 4 digits", ...inputArray.map((obj) => obj.accessCode.slice(-4))]);
    addRow(["# of Employees", ...basicData.map(obj => obj.company?.nrOfEmployees)]);
    addRow(["Recent Major Improvement used", ...basicData.map(obj => (obj.company?.otherImprovements || obj.company?.improvementsUsed || "").replaceAll(',', ''))]);
    addRow(["Most Vulnerable process name", ...inputArray.map(obj => obj.mostVulnerableProcess || "None")]);
    addRow(["Rating", ...inputArray.map((obj) => obj.selection)]);
    addRow(["Prescription ID #", ...inputArray.map(obj => obj.mostVulnerableProcessId || "None")]);
    addRow(["Assessor Note from Most Vulnerable process", ...inputArray.map(obj => obj.mostVulnerableProcessNotes || "None")]);

    // Convert the structured rows into a CSV string, applying the escape function
    const finalCSV = [headers.map(escapeCSV).join(",")];
    csvRows.forEach(row => {
        finalCSV.push(row.map(escapeCSV).join(","));
    });

    return finalCSV.join("\n");
};


export const generateCSVData = (inputArray) => {
    // Define headers
    const headers = ["Data Variables"];
    inputArray.forEach((_, index) => headers.push(`Assessment ${index + 1}`));

    // Initialize row structure
    const csvRows = [];

    // Function to add data rows
    const addRow = (values) => {
        csvRows.push(values);
    };

    // Process metadata only once
    addRow(["Assessment Date", ...inputArray.map((obj) => new Date(obj.dateAssessed * 1000).toLocaleDateString())]);
    addRow(["UID Last 4 digits", ...inputArray.map((obj) => obj.accessCode.slice(-4))]);
    addRow(["Assessment Type", ...Array(inputArray.length).fill("VIA-Audit")]);
    // addRow(["Vulnerable Quadrant", ...inputArray.map((obj) => obj.vulnerable)]);

    // Calculate category averages and most vulnerable process
    const categoryAverages = inputArray.map(input => {
        const averages = {};
        // Find category with lowest average
        let lowestCategory = null;
        let lowestAverage = Infinity;
        Object.keys(averages).forEach(category => {
            const avg = averages[category].total / averages[category].count;
            if (avg < lowestAverage) {
                lowestAverage = avg;
                lowestCategory = category;
            }
        });

        return { lowestCategory, lowestAverage: lowestAverage.toFixed(2) };
    });

    // Define category order
    const categoryOrder = ["Product", "Market", "Business", "Commercialization"];

    // Process categories and their processes
    categoryOrder.forEach(category => {
        const uniqueProcesses = new Set();

        // Collect all unique processes under this category
        inputArray.forEach(input => {
            Object.keys(input).forEach(key => {
                if (!isNaN(key)) {
                    const { category: processCategory, processesName } = input[key];
                    if (processCategory === category) {
                        uniqueProcesses.add(processesName);
                    }
                }
            });
        });

        // Only add category if it has processes
        if (uniqueProcesses.size > 0) {
            addRow([]);
            addRow([`${category.toUpperCase()} CATEGORY`, ""]);

            // Add each unique process under its category
            uniqueProcesses.forEach(processName => {
                addRow([processName, ...inputArray.map(obj => {
                    const process = Object.values(obj).find(p => p.processesName === processName);
                    return process ? process.selection : "";
                })]);
            });
        }
    });

    // Convert the structured rows into a CSV string
    const finalCSV = [headers.join(",")];
    csvRows.forEach(row => {
        finalCSV.push(row.join(","));
    });

    return finalCSV.join("\n");
}