import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
//ui
import { Box, Button, Container, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import { Helmet } from 'react-helmet-async'
//pages - main report
import CoverPage from 'src/components/reports/vsba/main/CoverPage'
import TocPage from 'src/components/reports/vsba/main/TocPage'
import IntroPage from 'src/components/reports/vsba/main/IntroPage'
import MaturityPlotPage from 'src/components/reports/vsba/main/MaturityPlotPage'
import InterpretingPlotPage from 'src/components/reports/vsba/main/InterpretingPlotPage'
import InterpretingPlotPage2 from 'src/components/reports/vsba/main/InterpretingPlotPage2'
import InterpretingPlotPage3 from 'src/components/reports/vsba/main/InterpretingPlotPage3'
import ProcessRatingsPage from 'src/components/reports/vsba/main/ProcessRatingsPage'
import Charts from 'src/components/reports/vsba/main/Charts'
import Charts2 from 'src/components/reports/vsba/main/Charts2'
import VulnerableProcessPage from 'src/components/reports/vsba/main/VulnerableProcessPage'
import ClosingAdvicePage from 'src/components/reports/vsba/main/ClosingAdvicePage'
import FinalAdvicePage from 'src/components/reports/vsba/main/FinalAdvicePage'
import ProcessAdvicePage from 'src/components/reports/vsba/main/ProcessAdvicePage'
//components
import PageLayout from 'src/components/reports/PageLayout'
//pdf generator
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import moment from 'moment'
import ClosingAdvicePage2 from 'src/components/reports/vsba/main/ClosingAdvicePage2'
import ReportLoading from 'src/components/shared/ReportLoading'


const DownloadReport = (props) => {
    //redux
    const {
        axisSequence,
        finalEvaluation,
        reportContent,
        assessorData,
        isGraphicLoading,
        dateAssessed,
        assessmentType
    } = props;


    //state
    const [pageLoading, setPageLoading] = useState(true)
    const [pageLoaded, setPageLoaded] = useState(false)
    const [mainLoading, setMainLoading] = useState(false)
    const [execLoading, setExecLoading] = useState(false)
    const [notesLoading, setNotesLoading] = useState(false)
    const [hideFooter, setHideFooter] = useState(false)
    const [mainReport, setMainReport] = useState([])
    const [mainPDF, setMainPDF] = useState(null)
    const [execPDF, setExecPDF] = useState(null)
    const [notesPDF, setNotesPDF] = useState(null)

    // const [assessorData, setAssessorData] = useState('')

    let _reportContent = reportContent.find(report => report.reportId === 'via')

    //use in pdf
    const fullName = assessorData.assessor?.name ? `Assessor: ${assessorData.assessor.name}` : ''
    const companyName = assessorData.company?.name ? `Company: ${assessorData.company.name}` : ''
    const projectName = assessorData.company?.projectName ? `Project: ${assessorData.company.projectName}` : ''
    const _dateAssessed = moment.unix(dateAssessed?.seconds).format('MM-DD-YYYY')
    const teamLead = `Team Lead Name:  ${assessorData.company?.pointOfContact ? assessorData.company.pointOfContact : ''}`

    //other variables
    const classes = useStyles()
    const navigate = useNavigate()

    useEffect(async () => {

        await buildMainAdvicePages()

    }, [finalEvaluation])

    //dynamically creates main report advice pages
    const buildMainAdvicePages = async () => {
        /*
         * set main report pages
         */

        try {
            if (!axisSequence) {
                return
            }

            //pages in report
            const pages = [
                <CoverPage />,
                <TocPage />,
                <IntroPage />,
                <MaturityPlotPage />,
                <InterpretingPlotPage />,
                <InterpretingPlotPage2 item40={_reportContent.report[40]?.content} />,
                <InterpretingPlotPage3 />,
                <ProcessRatingsPage />,
                <Charts />,
                <Charts2 />,
                <VulnerableProcessPage />,
                'Process Advice', //used below  to return dynamic quantinty of  pages needed for process advice
                <ClosingAdvicePage />,
                <ClosingAdvicePage2 />,
                <FinalAdvicePage />
            ]

            //set reverse order to grab weakest axis first
            let adviceArr = axisSequence.slice();
            adviceArr.reverse();
            //copy and use dummy
            let evalArr = finalEvaluation.slice()

            //inject Process Advice pages
            pages.forEach((page, index) => {
                //temp - one page
                if (page == 'Process Advice') {
                    let isFirstAdvice = true;
                    let firstSpliceIndex = 0;
                    let secondSpliceIndex = 0;
                    let content = '';
                    let content_2 = '';
                    let refMsg = 'refMsg failed'
                    let scoreIntro = 'scoreIntro failed'

                    //check for all equals test out case (grabs raw sum = third value in array)
                    if (adviceArr[0][2] === 24 && adviceArr[1][2] === 24 && adviceArr[2][2] === 24 && adviceArr[3][2] === 24) {
                        pages.splice(index, 1,
                            <ProcessAdvicePage
                                isFirstReport={true}
                                isFirstProcess={true}
                                isFirstPage={true}
                                // process={}
                                categoryName={''}
                                didFullTestOut={true}
                                content={_reportContent.report[79].content[4].content}
                            />
                        )

                        //page 2
                        pages.splice(index + 1, 0,
                            <ProcessAdvicePage
                                isFirstReport={true}
                                isFirstProcess={true}
                                isFirstPage={false}
                                // process={}
                                categoryName={''}
                                didFullTestOut={true}
                                content={_reportContent.report[79].content[4].content_2}
                                referralMsg={_reportContent.report[80].content[4].content}
                            />
                        )
                        return;
                    }

                    //loop through axis sequence and determine process advices to be added to report
                    adviceArr.forEach((axis, axisIndex) => {

                        //loop through number of process advice per axis and map pages
                        for (let i = 0; i < axis[1]; i++) {
                            //set splice index for injected advice
                            firstSpliceIndex = (isFirstAdvice ? index + axisIndex : secondSpliceIndex + 1)
                            secondSpliceIndex = firstSpliceIndex + 1
                            //loop through categories to map advice
                            evalArr.forEach(section => {
                                if (section.axis == axis[0]) {
                                    refMsg = ''
                                    content = ""
                                    content_2 = ""
                                    //sort by selection
                                    let isFirstProcess = i === 0 ? true : false;
                                    //sort processes by lowest selection then rank, display advice when match
                                    let processOrder = section.processes.slice();

                                    processOrder.sort((a, b) => {
                                        if (a.selection === b.selection) {
                                            return a.rank < b.rank ? -1 : 1
                                        } else {
                                            return a.selection < b.selection ? -1 : 1
                                        }
                                    })

                                    //tests out if the process in scope equals 3
                                    const didTestOut = processOrder[i].selection === 3;
                                    scoreIntro = _reportContent.report[68].content[processOrder[i].selection].content

                                    //set advice (matches report vs content colletions)
                                    if (!didTestOut) {
                                        switch (section.axis) {
                                            case 'North':
                                                // console.log('North: ', processOrder[i])
                                                if (processOrder[i].id === 1) {
                                                    content = _reportContent.report[69].content[0].content
                                                    content_2 = _reportContent.report[69].content[0].content_2
                                                    refMsg = _reportContent.report[70].content[0].content
                                                } else if (processOrder[i].id === 2) {
                                                    content = _reportContent.report[69].content[1].content
                                                    content_2 = _reportContent.report[69].content[1].content_2
                                                    refMsg = _reportContent.report[70].content[1].content
                                                } else if (processOrder[i].id === 3) {
                                                    content = _reportContent.report[69].content[2].content
                                                    content_2 = _reportContent.report[69].content[2].content_2
                                                    refMsg = _reportContent.report[70].content[2].content
                                                } else if (processOrder[i].id === 4) {
                                                    content = _reportContent.report[69].content[3].content
                                                    content_2 = _reportContent.report[69].content[3].content_2
                                                    refMsg = _reportContent.report[70].content[3].content
                                                } else if (processOrder[i].id === 5) {
                                                    content = _reportContent.report[69].content[4].content
                                                    content_2 = _reportContent.report[69].content[4].content_2
                                                    refMsg = _reportContent.report[70].content[4].content
                                                } else if (processOrder[i].id === 6) {
                                                    content = _reportContent.report[69].content[5].content
                                                    content_2 = _reportContent.report[69].content[5].content_2
                                                    refMsg = _reportContent.report[70].content[5].content
                                                } else if (processOrder[i].id === 7) {
                                                    content = _reportContent.report[69].content[6].content
                                                    content_2 = _reportContent.report[69].content[6].content_2
                                                    refMsg = _reportContent.report[70].content[6].content
                                                } else if (processOrder[i].id === 8) {
                                                    content = _reportContent.report[69].content[7].content
                                                    content_2 = _reportContent.report[69].content[7].content_2
                                                    refMsg = _reportContent.report[70].content[7].content
                                                }
                                                break;
                                            case 'West':
                                                // console.log('West: ', processOrder[i])
                                                if (processOrder[i].id === 1) {
                                                    content = _reportContent.report[69].content[8].content
                                                    content_2 = _reportContent.report[69].content[8].content_2
                                                    refMsg = _reportContent.report[70].content[8].content
                                                } else if (processOrder[i].id === 2) {
                                                    content = _reportContent.report[69].content[9].content
                                                    content_2 = _reportContent.report[69].content[9].content_2
                                                    refMsg = _reportContent.report[70].content[9].content
                                                } else if (processOrder[i].id === 3) {
                                                    content = _reportContent.report[69].content[10].content
                                                    content_2 = _reportContent.report[69].content[10].content_2
                                                    refMsg = _reportContent.report[70].content[10].content
                                                } else if (processOrder[i].id === 4) {
                                                    content = _reportContent.report[69].content[11].content
                                                    content_2 = _reportContent.report[69].content[11].content_2
                                                    refMsg = _reportContent.report[70].content[11].content
                                                } else if (processOrder[i].id === 5) {
                                                    content = _reportContent.report[69].content[12].content
                                                    content_2 = _reportContent.report[69].content[12].content_2
                                                    refMsg = _reportContent.report[70].content[12].content
                                                } else if (processOrder[i].id === 6) {
                                                    content = _reportContent.report[69].content[13].content
                                                    content_2 = _reportContent.report[69].content[13].content_2
                                                    refMsg = _reportContent.report[70].content[13].content
                                                } else if (processOrder[i].id === 7) {
                                                    content = _reportContent.report[69].content[14].content
                                                    content_2 = _reportContent.report[69].content[14].content_2
                                                    refMsg = _reportContent.report[70].content[14].content
                                                } else if (processOrder[i].id === 8) {
                                                    content = _reportContent.report[69].content[15].content
                                                    content_2 = _reportContent.report[69].content[15].content_2
                                                    refMsg = _reportContent.report[70].content[15].content
                                                } else {
                                                    content = "error content is off"
                                                    content_2 = "error content is off"
                                                }
                                                break;
                                            case 'South':
                                                // console.log('South: ', processOrder[i])
                                                if (processOrder[i].id === 1) {
                                                    content = _reportContent.report[69].content[16].content
                                                    content_2 = _reportContent.report[69].content[16].content_2
                                                    refMsg = _reportContent.report[70].content[16].content
                                                } else if (processOrder[i].id === 2) {
                                                    content = _reportContent.report[69].content[17].content
                                                    content_2 = _reportContent.report[69].content[17].content_2
                                                    refMsg = _reportContent.report[70].content[17].content
                                                } else if (processOrder[i].id === 3) {
                                                    content = _reportContent.report[69].content[18].content
                                                    content_2 = _reportContent.report[69].content[18].content_2
                                                    refMsg = _reportContent.report[70].content[18].content
                                                } else if (processOrder[i].id === 4) {
                                                    content = _reportContent.report[69].content[19].content
                                                    content_2 = _reportContent.report[69].content[19].content_2
                                                    refMsg = _reportContent.report[70].content[19].content
                                                } else if (processOrder[i].id === 5) {
                                                    content = _reportContent.report[69].content[20].content
                                                    content_2 = _reportContent.report[69].content[20].content_2
                                                    refMsg = _reportContent.report[70].content[20].content
                                                } else if (processOrder[i].id === 6) {
                                                    content = _reportContent.report[69].content[21].content
                                                    content_2 = _reportContent.report[69].content[21].content_2
                                                    refMsg = _reportContent.report[70].content[21].content
                                                } else if (processOrder[i].id === 7) {
                                                    content = _reportContent.report[69].content[22].content
                                                    content_2 = _reportContent.report[69].content[22].content_2
                                                    refMsg = _reportContent.report[70].content[22].content
                                                } else if (processOrder[i].id === 8) {
                                                    content = _reportContent.report[69].content[23].content
                                                    content_2 = _reportContent.report[69].content[23].content_2
                                                    refMsg = _reportContent.report[70].content[23].content
                                                } else {
                                                    content = "error content is off"
                                                    content_2 = "error content is off"
                                                }
                                                break;
                                            case 'East':
                                                // console.log('East: ', processOrder[i])
                                                if (processOrder[i].id === 1) {
                                                    content = _reportContent.report[69].content[24].content
                                                    content_2 = _reportContent.report[69].content[24].content_2
                                                    refMsg = _reportContent.report[70].content[24].content
                                                } else if (processOrder[i].id === 2) {
                                                    content = _reportContent.report[69].content[25].content
                                                    content_2 = _reportContent.report[69].content[25].content_2
                                                    refMsg = _reportContent.report[70].content[25].content
                                                } else if (processOrder[i].id === 3) {
                                                    content = _reportContent.report[69].content[26].content
                                                    content_2 = _reportContent.report[69].content[26].content_2
                                                    refMsg = _reportContent.report[70].content[26].content
                                                } else if (processOrder[i].id === 4) {
                                                    content = _reportContent.report[69].content[27].content
                                                    content_2 = _reportContent.report[69].content[27].content_2
                                                    refMsg = _reportContent.report[70].content[27].content
                                                } else if (processOrder[i].id === 5) {
                                                    content = _reportContent.report[69].content[28].content
                                                    content_2 = _reportContent.report[69].content[28].content_2
                                                    refMsg = _reportContent.report[70].content[28].content
                                                } else if (processOrder[i].id === 6) {
                                                    content = _reportContent.report[69].content[29].content
                                                    content_2 = _reportContent.report[69].content[29].content_2
                                                    refMsg = _reportContent.report[70].content[29].content
                                                } else if (processOrder[i].id === 7) {
                                                    content = _reportContent.report[69].content[30].content
                                                    content_2 = _reportContent.report[69].content[30].content_2
                                                    refMsg = _reportContent.report[70].content[30].content
                                                } else if (processOrder[i].id === 8) {
                                                    content = _reportContent.report[69].content[31].content
                                                    content_2 = _reportContent.report[69].content[31].content_2
                                                    refMsg = _reportContent.report[70].content[31].content
                                                } else {
                                                    content = "error content is off"
                                                    content_2 = "error content is off"
                                                }
                                                break;
                                            default:
                                                console.log('no axis match')
                                                break;
                                        }
                                    } else {
                                        //test out msg
                                        switch (section.axis) {
                                            case 'North':
                                                content = _reportContent.report[79].content[0].content
                                                content_2 = _reportContent.report[79].content[0].content_2
                                                refMsg = _reportContent.report[80].content[0].content
                                                break;
                                            case 'West':
                                                content = _reportContent.report[79].content[1].content
                                                content_2 = _reportContent.report[79].content[1].content_2
                                                refMsg = _reportContent.report[80].content[1].content
                                                break;
                                            case 'South':
                                                content = _reportContent.report[79].content[2].content
                                                content_2 = _reportContent.report[79].content[2].content_2
                                                refMsg = _reportContent.report[80].content[2].content
                                                break;
                                            case 'East':
                                                content = _reportContent.report[79].content[3].content
                                                content_2 = _reportContent.report[79].content[3].content_2
                                                refMsg = _reportContent.report[80].content[3].content
                                                break;
                                            default:
                                                console.log('no axis match')
                                                break;
                                        }
                                    }

                                    //remove array placeholder if first instance, add process advice pages
                                    pages.splice(firstSpliceIndex, (isFirstAdvice ? 1 : 0),
                                        <ProcessAdvicePage
                                            isFirstReport={isFirstAdvice}
                                            isFirstProcess={isFirstProcess}
                                            isFirstPage={true}
                                            process={processOrder[i]}
                                            categoryName={section.category}
                                            didTestOut={didTestOut}//{section.rawSum === 24}
                                            testoutMsg={section.testout}
                                            content={content}
                                            scoreIntro={scoreIntro}
                                        />
                                    )


                                    //page 2
                                    pages.splice(secondSpliceIndex, 0,
                                        <ProcessAdvicePage
                                            isFirstReport={isFirstAdvice}
                                            isFirstProcess={isFirstProcess}
                                            isFirstPage={false}
                                            process={processOrder[i]}
                                            categoryName={section.category}
                                            didTestOut={didTestOut}//{section.rawSum === 24}
                                            testoutMsg={section.testout}
                                            content={content_2}
                                            referralMsg={refMsg}
                                            scoreIntro={scoreIntro}
                                        />
                                    )

                                    isFirstAdvice = false;
                                    return;
                                }
                            })
                        }
                    })
                }
            })

            setMainReport(pages)
            setPageLoading(false)

        } catch (e) {
            console.log('AssessmentReport load error: ', e.message)
            setPageLoading(false)
        }
    }

    //recursive function to print multi page report from page
    const printImagePDF = (pdfName, idTag, getBlob) => {

        let imgWidth = 208;
        let scale = { scale: 1 };
        const pdf = new jsPDF('p', 'mm', 'a4', true);
        let i = 0;

        if (mainPDF && !getBlob) {
            mainPDF.save(pdfName)
            return
        }

        //recursive funcitons that keeps rendering
        const getCanvas = () => {
            const element = document.getElementById(idTag + i);
            if (element) {
                html2canvas(element, scale)
                    .then(canvas => {

                        let imgHeight = canvas.height * imgWidth / canvas.width;
                        let imgData = canvas.toDataURL('image/jpeg', 0.8);

                        //adding multiple pages after first is created
                        if (i > 1) {
                            pdf.addPage('a4', 'portrait');
                        }

                        pdf.addImage(imgData, 'PNG', -15, -10, imgWidth + 25, imgHeight + 10, undefined, 'FAST');

                        //print after last page is added
                        if (i === mainReport.length) {

                            if (!getBlob) {
                                //download report 
                                pdf.save(pdfName);
                            } else {
                                //or updated backend with blob and set mainPDf for easy downlaod
                                console.log('--- main blob --- ', pdf.output('blob'))
                                setMainPDF(pdf)
                                setMainBlob(pdf.output('blob'))
                            }

                            setMainLoading(false)
                        } else {
                            //or keep adding pagess
                            try {
                                getCanvas()
                            } catch (e) {
                                console.log('getCanvas inner error: ', e.message)
                                setMainLoading(false)
                            }
                        }
                    })
                i++;
            } else {
                if (i > 10) {
                    if (!getBlob) {
                        //download report 
                        pdf.save(pdfName);
                    } else {
                        //or updated backend with blob and set mainPDf for easy downlaod
                        console.log('--- main blob --- ', pdf.output('blob'))
                        setMainPDF(pdf)
                        setMainBlob(pdf.output('blob'))
                    }
                }
                setMainLoading(false)
            }
        }
        try {
            //init canvas to pdf
            setMainLoading(true)
            getCanvas()
        } catch (e) {
            console.log('getCanvas error: ', e.message)
            setMainLoading(false)
        }

    }

    //temp - remove
    // const returnAssessment = () => {
    //     editReport()
    //     navigate('/assessment/evaluation', { replace: true })
    // }


    //companyname-projectname-assessmentdate

    //generates pdf and saves or stores blob based on getBlob prop
    const buildExecutiveReport = async (getBlob) => {
        let pdfName = `VIA-${assessmentType === 'VIA Audit' ? 'Audit' : 'Biz'}-Executive-Report-${companyName}-${projectName}-${_dateAssessed}.pdf`

        //save pdf if already created 
        if (execPDF && !getBlob) {
            execPDF.save(pdfName)
            return
        }

        //hide footer - so that main report footer doesnt show on page

        setHideFooter(true)
        setExecLoading(true)

        setTimeout(async () => {



            const pdf = new jsPDF('p', 'mm', 'a4', true)
            let text = ''
            const date = moment(new Date()).format("M/D/YYYY")

            //measurements
            const pageHeight = pdf.internal.pageSize.height
            const pageWidth = pdf.internal.pageSize.width
            const halfWidth = pageWidth / 2
            const margin = 20
            const topMargin = 20
            const rightMargin = pageWidth - margin
            const textLarge = 17
            const textMedLarge = 15
            const textMedium = 13
            const textRegular = 11
            const textSmall = 9
            const maxWidth = (pageWidth - (margin * 2))
            const fontType = 'helvetica'
            const lineWidth = 190;

            //logo
            const logo = '/static/logo.png';
            const VIALogo = '/static/images/VIA-logo.png'
            const VIAAuditLogo = '/static/images/VIA-Audit.png'
            let logoWidth = 40;
            let logoHeight = logoWidth * 0.456;
            let logoX = (pageWidth - logoWidth) / 2

            //canvas
            let scale = { scale: 1 };

            const _logo = assessmentType === 'VIA Audit' ? VIAAuditLogo : VIALogo

            //pdf.setFont(fontType)

            /*
             * 1) cover page
             */

            pdf.addImage(logo, logoX, topMargin + 15, logoWidth, logoHeight);
            pdf.setFontSize(textLarge);
            pdf.setFont(fontType, "bold");
            pdf.text("E X E C U T I V E   S U M M A R Y", pageWidth / 2, 75, { align: 'center' });

            pdf.setFont(fontType);
            pdf.setFontSize(textSmall);
            pdf.setFont(fontType, "italic");
            pdf.text("of the", halfWidth, 90, { align: 'center' });

            pdf.setFontSize(textMedium);
            pdf.setFont(fontType, "bold");
            pdf.addImage(_logo, logoX - 15, assessmentType === 'VIA Audit' ? 93.5 : 95.5, 40, assessmentType === 'VIA Audit' ? 10 : 5);
            pdf.text("Assessment", halfWidth + 19, 100, { align: 'center' });

            // pdf.setFontSize(textLarge);
            // pdf.setFont(fontType, "normal");
            pdf.setDrawColor(211, 211, 211)

            pdf.line(lineWidth, 137, margin, 137)

            pdf.setFontSize(textRegular);
            pdf.setFont(fontType, 'normal');

            pdf.text(companyName, margin, 145, { align: 'left' });
            pdf.text(projectName, rightMargin, 145, { align: 'right' });

            pdf.text(fullName, margin, 150, { align: 'left' });
            pdf.text(`Date: ${date}`, rightMargin, 150, { align: 'right' });
            pdf.line(lineWidth, 156, margin, 156)

            text = 'This report provides the high level aggregated and summarized results about your most vulnerable business capability recommendation derived from your assessor\'s ratings.'
            pdf.text(text, halfWidth, 180, { align: 'center', maxWidth: maxWidth })

            pdf.setDrawColor(0, 0, 0);
            pdf.line(lineWidth, 215, margin, 215)

            const abilityGuide = '/static/images/vsba/cover-graph.png'
            const aGuideLength = 40;
            text = 'Should you wish to see more information about this particular result. Please ask your Assessor for a copy of the Full Report.'
            pdf.text(text, margin, 235, { align: 'left', maxWidth: maxWidth - 45 });
            pdf.addImage(abilityGuide, pageWidth - aGuideLength - margin, 230, aGuideLength, aGuideLength);

            pdf.setFontSize(textSmall);
            pdf.text("1", rightMargin, pageHeight - 10, { align: 'center' });

            pdf.setFontSize(textRegular);

            const finishExec = () => {
                pdf.addPage('a4', 'portrait');
                pdf.addImage(logo, logoX, topMargin + 20, logoWidth, logoHeight);
                pdf.setFont(fontType, "bold");
                pdf.setFontSize(textRegular);
                pdf.text('Important Disclaimer Note', margin, topMargin + 190)

                pdf.setFontSize(textSmall);
                pdf.setFont(fontType, "normal");
                pdf.setFont(fontType)

                text = 'The results of this assessment are based upon your assessor\'s individual knowledge of the business or organization being evaluated. As such, supplying inaccurate rating information can and will likely produce inaccurate results. However, all the recommendations are based in solid practices and the effect of an inaccurate rating would be to work on solid practices in a less efficient sequence.'
                pdf.text(text, margin, topMargin + 200, { maxWidth: maxWidth })
                text = 'This assessment is designed to highlight missing or inadequate processes that build to the four critical capabilities. Failure to mature these capabilities in a timely manner could hinder future success. Please refer to the full set of disclaimers on our website.'
                pdf.text(text, margin, topMargin + 220, { maxWidth: maxWidth })

                pdf.setFontSize(textRegular);
                pdf.setFont(fontType)
                pdf.text("VectorReports.com", halfWidth, topMargin + 250, { align: 'center' });

                pdf.setFontSize(textSmall);
                pdf.text("6", rightMargin, pageHeight - 10, { align: 'center' });
                if (!getBlob) {
                    //print
                    pdf.save(pdfName);
                } else {
                    //return blob
                    console.log('--- exec blob --- ', pdf.output('blob'));
                    setExecPDF(pdf)
                    setExecBlob(pdf.output('blob'))
                }
                setExecLoading(false)
                //show footer
                setHideFooter(false)
            }

            //capabilities
            let imgWidth = 208;
            const capabilities = document.getElementById('mainreport-3');
            html2canvas(capabilities, scale)
                .then(canvas => {

                    let imgHeight = canvas.height * imgWidth / canvas.width;
                    let imgData = canvas.toDataURL('image/jpeg', 0.6);
                    pdf.addPage('a4', 'portrait');
                    pdf.addImage(imgData, 'PNG', -4, 0, imgWidth, imgHeight, undefined, 'FAST');

                    pdf.setFontSize(textSmall);
                    pdf.text("2", rightMargin, pageHeight - 10, { align: 'center' });

                    //capabilities1
                    const capabilities1 = document.getElementById('mainreport-4');
                    html2canvas(capabilities1, scale)
                        .then(canvas => {

                            let imgHeight = canvas.height * imgWidth / canvas.width;
                            let imgData = canvas.toDataURL('image/jpeg', 0.6);
                            pdf.addPage('a4', 'portrait');
                            pdf.addImage(imgData, 'PNG', -4, 0, imgWidth, imgHeight, undefined, 'FAST');
                            pdf.text("3", rightMargin, pageHeight - 10, { align: 'center' });
                            //capabilities2
                            const capabilities2 = document.getElementById('mainreport-5');
                            html2canvas(capabilities2, scale)
                                .then(canvas => {

                                    let imgHeight = canvas.height * imgWidth / canvas.width;
                                    let imgData = canvas.toDataURL('image/jpeg', 0.6);
                                    pdf.addPage('a4', 'portrait');
                                    pdf.addImage(imgData, 'PNG', -4, 0, imgWidth, imgHeight, undefined, 'FAST');
                                    pdf.text("4", rightMargin, pageHeight - 10, { align: 'center' });
                                    //capabilities3
                                    const capabilities3 = document.getElementById('mainreport-6');
                                    html2canvas(capabilities3, scale)
                                        .then(canvas => {

                                            let imgHeight = canvas.height * imgWidth / canvas.width;
                                            let imgData = canvas.toDataURL('image/jpeg', 0.6);
                                            pdf.addPage('a4', 'portrait');
                                            pdf.addImage(imgData, 'PNG', -4, 0, imgWidth, imgHeight, undefined, 'FAST');
                                            pdf.text("5", rightMargin, pageHeight - 10, { align: 'center' });
                                            finishExec()
                                        })
                                })
                        })
                })

        }, 1000)

    }


    const buildNotesTitles = (pdf, string, x, y) => {
        let startX = x;
        const fontSize = 11;

        const arrayOfNormalAndBoldText = string.split('**')

        arrayOfNormalAndBoldText.map((text, i) => {
            pdf.setFontSize(fontSize);
            pdf.setFont('helvetica', "bold");
            // every even item is a normal font weight item
            if (i % 2 === 0) {
                pdf.setFont('helvetica', "normal");
            }
            pdf.text(text, startX, y);
            startX = startX + pdf.getStringUnitWidth(text) * 11.05
        })
    }

    const buildNotesReport = async (getBlob) => {
        let pdfName = `VIA-${assessmentType === 'VIA Audit' ? 'Audit' : 'Biz'}-Notes-Report-${companyName}-${projectName}-${_dateAssessed}.pdf`

        if (notesPDF && !getBlob) {
            notesPDF.save(pdfName)
            return
        }

        setNotesLoading(true)
        let text = ''

        const pdf = new jsPDF('p', 'pt', 'a4', true)
        const date = moment(new Date()).format("M/D/YYYY")

        //measurements
        const pageHeight = pdf.internal.pageSize.height
        const pageWidth = pdf.internal.pageSize.width
        const halfWidth = pageWidth / 2
        const margin = 40
        const topMargin = 50
        const rightMargin = pageWidth - margin
        const textXL = 20
        const textLarge = 17
        const textMedLarge = 15
        const textMedium = 13
        const textRegular = 11
        const textSmall = 9
        const maxWidth = (pageWidth - (margin * 2))
        const fontType = 'helvetica'
        const lineWidth = 190 * 3;

        //logo
        const logo = '/static/logo.png';
        const VIALogo = '/static/images/VIA-logo.png'
        const VIAAuditLogo = '/static/images/VIA-Audit.png'

        const _logo = assessmentType === 'VIA Audit' ? VIAAuditLogo : VIALogo
        const _viaLogoHeight = assessmentType === 'VIA Audit' ? 15 : 10

        let logoWidth = 120;
        let logoHeight = logoWidth * 0.456;
        let logoX = (pageWidth - logoWidth) / 2

        //canvas
        let scale = { scale: 2 };

        //pdf.setFont(fontType)

        // buildNotesTitles(pdf, "The purpose of the solution which suggests and requires certain **form(s)**", 20)
        // buildNotesTitles(pdf, "Criteria the customer judges value of the offering relative to the **utility** of the solution", 35)

        /*
         * 1) cover page
         */

        pdf.addImage(logo, logoX, topMargin + 45, logoWidth, logoHeight);

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType)
        pdf.text('Collected', pageWidth / 2, topMargin + 135, { align: 'center' })

        pdf.setFontSize(textXL);
        pdf.setFont(fontType, "bold")
        pdf.text("N  O  T  E  S", pageWidth / 2, topMargin + 168, { align: 'center' })

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "normal")
        pdf.setFont(fontType)
        pdf.text('from your', pageWidth / 2, topMargin + 195, { align: 'center' })

        pdf.setFontSize(textMedium);
        pdf.setFont(fontType, "bold");

        pdf.addImage(_logo, logoX - 20, topMargin + 231.8, 80, assessmentType === 'VIA Audit' ? 20 : 15);
        pdf.text("Assessment", halfWidth + 40, topMargin + 245, { align: 'center' });

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "normal")
        pdf.setFont(fontType)
        pdf.text('Ratings', pageWidth / 2, topMargin + 269, { align: 'center' })

        // pdf.setFontSize(textLarge);
        // pdf.setFont(fontType, "normal");
        // pdf.text(companyName, margin, 130);

        pdf.setDrawColor(0, 0, 0)

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "normal");

        pdf.line(lineWidth, 360, margin, 360)

        pdf.text(companyName, margin, 385, { align: 'left' });
        pdf.text(projectName, rightMargin, 385, { align: 'right' });

        pdf.text(fullName, margin, 400, { align: 'left' });
        pdf.text(`Date: ${date}`, rightMargin, 400, { align: 'right' });
        pdf.line(lineWidth, 415, margin, 415)

        pdf.setDrawColor(0, 0, 0);
        text = 'This document reports back to you any informal notes and comments that were collected by your assessor during the assessment rating session. Vector Reports provides this only as a courtesy and does not attempt to translate, validate nor represent the content of your assessor\'s note-taking efforts.'
        pdf.text(text, margin, 500, { maxWidth: maxWidth })
        pdf.line(lineWidth, pageHeight - 240, margin, pageHeight - 240)

        const abilityGuide = '/static/images/vsba/cover-graph.png'
        const aGuideLength = 120;
        text = 'Please use this content appropriately and do not rely upon it until after internal validation by your assessor or business.'
        pdf.text(text, margin, pageHeight - 200, { align: 'left', maxWidth: maxWidth - 145 });
        pdf.addImage(abilityGuide, pageWidth - aGuideLength - margin, pageHeight - 200, aGuideLength, aGuideLength);

        pdf.setFontSize(textSmall);
        pdf.text("1", rightMargin, pageHeight - 20, { align: 'center' });
        /*
         * 2) product page
         */

        pdf.addPage('a4', 'portrait');

        pdf.addImage(_logo, logoX + 20, 20, 40, _viaLogoHeight);
        pdf.text('Assessment', logoX + 110, 29, { align: 'right' });

        pdf.setFontSize(textMedium);
        pdf.text('Your Process Maturity Ratings by Category', margin, topMargin)

        pdf.setFontSize(textSmall);
        pdf.text(companyName, rightMargin, topMargin + 9.5, { align: 'right' });
        pdf.text(date, rightMargin, topMargin + 19, { align: 'right' });

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Product", margin + 10, topMargin + 45);

        pdf.setFontSize(textSmall);
        pdf.setFont(fontType, "normal");
        pdf.text("Ratings:", margin + 80, topMargin + 120);

        const productChart = document.getElementById("productChart");
        const productCanvas = await html2canvas(productChart, scale);
        const imgData = productCanvas.toDataURL('image/jpeg', 0.9);
        pdf.addImage(imgData, margin + 140, topMargin + 50, halfWidth - 70, (halfWidth) * .5)

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Notes: Collected during the assessment", halfWidth, topMargin + 230, { align: 'center' });

        pdf.setFontSize(textSmall);
        pdf.text("2", rightMargin, pageHeight - 20, { align: 'center' });

        pdf.setFont(fontType, "normal");
        pdf.setFont(fontType)
        //loop through collection get titles and notes
        let pHeight = topMargin + 270
        let pCounter = 1


        finalEvaluation.forEach(category => {
            if (category.category == 'Product') {
                category.processes.forEach(process => {
                    pdf.setFont(fontType, "italic", 'bold');
                    pdf.setFontSize(textRegular);

                    const categoryItem = `${pCounter}.  **${process.menuName}**: ${process.menuDescription ? process.menuDescription : ''}:`
                    buildNotesTitles(pdf, categoryItem, margin + 5, pHeight)

                    //pdf.getStringUnitWidth(process.menuName)

                    const textWidth = pdf.getTextWidth(`**${process.menuName}**`)
                    pdf.line(margin + 20.4, pHeight + 1, margin + 10 + textWidth, pHeight + 1)

                    pdf.setFont(fontType, 'normal', 'normal')
                    pdf.setFontSize(textSmall);
                    pdf.text(process.notes, margin + 10, pHeight + 12, { maxWidth: maxWidth - 10 })
                    pHeight = pHeight + 60
                    pCounter = pCounter + 1
                })
            }
        })

        /*
         * 3) market page
         */

        pdf.addPage('a4', 'portrait');

        pdf.setFontSize(textSmall);
        pdf.text("3", rightMargin, pageHeight - 10, { align: 'center' });

        pdf.addImage(_logo, logoX + 20, 20, 40, _viaLogoHeight);
        pdf.text('Assessment', logoX + 110, 29, { align: 'right' });

        pdf.setFontSize(textMedium);
        pdf.text('Your Process Maturity Ratings by Category', margin, topMargin)

        pdf.setFontSize(textSmall);
        pdf.text(companyName, rightMargin, topMargin + 9.5, { align: 'right' });
        pdf.text(date, rightMargin, topMargin + 19, { align: 'right' });

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Market", margin + 10, topMargin + 45);

        pdf.setFontSize(textSmall);
        pdf.setFont(fontType, "normal");
        pdf.text("Ratings:", margin + 80, topMargin + 120);

        const marketChart = document.getElementById("marketChart");
        const marketCanvas = await html2canvas(marketChart, scale);
        const imgData2 = marketCanvas.toDataURL('image/jpeg', 0.9);
        pdf.addImage(imgData2, margin + 140, topMargin + 50, halfWidth - 70, (halfWidth) * .5)

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Notes: Collected during the assessment", halfWidth, topMargin + 230, { align: 'center' });

        pdf.setFont(fontType, "normal");
        pdf.setFont(fontType)
        //loop through collection get titles and notes
        let mHeight = topMargin + 270
        let mCounter = 1
        finalEvaluation.forEach(category => {
            if (category.category == 'Market') {
                category.processes.forEach(process => {
                    pdf.setFont(fontType, "italic", 'bold');
                    pdf.setFontSize(textRegular);

                    const categoryItem = `${mCounter}.  **${process.menuName}**: ${process.menuDescription ? process.menuDescription : ''}:`

                    buildNotesTitles(pdf, categoryItem, margin + 5, mHeight)
                    const textWidth = pdf.getTextWidth(`**${process.menuName}**`)
                    pdf.line(margin + 20.4, mHeight + 1, margin + 10 + textWidth, mHeight + 1)

                    pdf.setFont(fontType, 'normal', 'normal')
                    pdf.setFontSize(textSmall);
                    pdf.text(process.notes, margin + 20, mHeight + 12, { maxWidth: maxWidth - 10 })
                    mHeight = mHeight + 60
                    mCounter = mCounter + 1
                })
            }
        })


        /*
         * 4) business page
         */

        pdf.addPage('a4', 'portrait');

        pdf.setFontSize(textSmall);
        pdf.text("4", rightMargin, pageHeight - 10, { align: 'center' });

        pdf.addImage(_logo, logoX + 20, 20, 40, _viaLogoHeight);
        pdf.text('Assessment', logoX + 110, 29, { align: 'right' });

        pdf.setFontSize(textMedium);
        pdf.text('Your Process Maturity Ratings by Category', margin, topMargin)

        pdf.setFontSize(textSmall);
        pdf.text(companyName, rightMargin, topMargin + 9.5, { align: 'right' });
        pdf.text(date, rightMargin, topMargin + 19, { align: 'right' });

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Business", margin + 10, topMargin + 45);

        pdf.setFontSize(textSmall);
        pdf.setFont(fontType, "normal");
        pdf.text("Ratings:", margin + 80, topMargin + 120);

        const businessChart = document.getElementById("businessChart");
        const businessCanvas = await html2canvas(businessChart, scale);
        const imgData3 = businessCanvas.toDataURL('image/jpeg', 0.9);
        pdf.addImage(imgData3, margin + 140, topMargin + 50, halfWidth - 70, (halfWidth) * .5)

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Notes: Collected during the assessment", halfWidth, topMargin + 230, { align: 'center' });

        pdf.setFont(fontType, "normal");
        pdf.setFont(fontType)
        //loop through collection get titles and notes
        let bHeight = topMargin + 270
        let bCounter = 1
        finalEvaluation.forEach(category => {
            if (category.category == 'Business') {
                category.processes.forEach(process => {

                    pdf.setFont(fontType, "italic", 'bold');
                    pdf.setFontSize(textRegular);

                    const categoryItem = `${bCounter}.  **${process.menuName}**: ${process.menuDescription ? process.menuDescription : ''}:`
                    buildNotesTitles(pdf, categoryItem, margin + 5, bHeight)

                    const textWidth = pdf.getTextWidth(`**${process.menuName}**`)
                    pdf.line(margin + 20.4, bHeight + 1, margin + 10 + textWidth, bHeight + 1)

                    pdf.setFont(fontType, 'normal', 'normal')
                    pdf.setFontSize(textSmall);
                    pdf.text(process.notes, margin + 10, bHeight + 12, { maxWidth: maxWidth - 10 })
                    bHeight = bHeight + 60
                    bCounter = bCounter + 1
                })
            }
        })

        /*
         * 5) comm page
         */

        pdf.addPage('a4', 'portrait');

        pdf.setFontSize(textSmall);
        pdf.text("5", rightMargin, pageHeight - 10, { align: 'center' });

        pdf.addImage(_logo, logoX + 20, 20, 40, _viaLogoHeight);
        pdf.text('Assessment', logoX + 110, 29, { align: 'right' });

        pdf.setFontSize(textMedium);
        pdf.text('Your Process Maturity Ratings by Category', margin, topMargin)

        pdf.setFontSize(textSmall);
        pdf.text(companyName, rightMargin, topMargin + 9.5, { align: 'right' });
        pdf.text(date, rightMargin, topMargin + 19, { align: 'right' });

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Commercialization", margin + 10, topMargin + 45);

        pdf.setFontSize(textSmall);
        pdf.setFont(fontType, "normal");
        pdf.text("Ratings:", margin + 80, topMargin + 120);

        const commChart = document.getElementById("commChart");
        const commCanvas = await html2canvas(commChart, scale);
        const imgData4 = commCanvas.toDataURL('image/jpeg', 0.9);
        pdf.addImage(imgData4, margin + 140, topMargin + 50, halfWidth - 70, (halfWidth) * .5)

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType, "bold");
        pdf.text("Notes: Collected during the assessment", halfWidth, topMargin + 230, { align: 'center' });

        pdf.setFont(fontType, "normal");
        pdf.setFont(fontType)
        //loop through collection get titles and notes
        let cHeight = topMargin + 270
        let cCounter = 1

        finalEvaluation.forEach(category => {
            if (category.category == 'Commercialization' || category.category == 'COMMERCIALIZATION' || category.category == 'COMMERCIALIZATION: Category of Processes') {
                category.processes.forEach(process => {

                    pdf.setFont(fontType, "italic", 'bold');
                    pdf.setFontSize(textRegular);

                    const categoryItem = `${cCounter}.  **${process.menuName}**: ${process.menuDescription ? process.menuDescription : ''}:`

                    buildNotesTitles(pdf, categoryItem, margin + 5, cHeight)
                    const textWidth = pdf.getTextWidth(`**${process.menuName}**`)
                    pdf.line(margin + 20.4, cHeight + 1, margin + 10 + textWidth, cHeight + 1)

                    pdf.setFont(fontType, 'normal', 'normal')
                    pdf.setFontSize(textSmall);
                    pdf.text(process.notes, margin + 10, cHeight + 12, { maxWidth: maxWidth - 10 })
                    cHeight = cHeight + 60
                    cCounter = cCounter + 1
                })
            }
        })


        /*
         * 6) closing page
         */

        pdf.addPage('a4', 'portrait');
        pdf.addImage(logo, logoX, topMargin + 20, logoWidth, logoHeight);

        pdf.setFontSize(textSmall);
        pdf.text("6", rightMargin, pageHeight - 10, { align: 'center' });

        pdf.setFont(fontType, "bold");
        pdf.setFontSize(textRegular);
        pdf.text('Important Disclaimer Note', margin, topMargin + 510)

        pdf.setFontSize(textSmall);
        pdf.setFont(fontType, "normal");
        pdf.setFont(fontType)

        text = 'The results of this assessment are based upon your assessor\'s individual knowledge of the business or organization being evaluated. As such, supplying inaccurate rating information can and will likely produce inaccurate results. However, all the recommendations are based in solid practices and the effect of an inaccurate rating would be to work on solid practices in a less efficient sequence.'
        pdf.text(text, margin, topMargin + 550, { maxWidth: maxWidth })
        text = 'This assessment is designed to highlight missing or inadequate processes that build to the four critical capabilities. Failure to mature these capabilities in a timely manner could hinder future success. Please refer to the full set of disclaimers on our website.'
        pdf.text(text, margin, topMargin + 600, { maxWidth: maxWidth })

        pdf.setFontSize(textRegular);
        pdf.setFont(fontType)
        pdf.text("VectorReports.com", halfWidth, topMargin + 650, { align: 'center' });


        if (!getBlob) {
            //print
            pdf.save(pdfName);
        } else {
            //return blob
            console.log('--- notes blob --- ', pdf.output('blob'));
            setNotesPDF(pdf)
            setNotesBlob(pdf.output('blob'))
        }
        setNotesLoading(false)
    }

    //keeps page from flashing as state is changed to show view after component mounts
    // if (!pageLoaded) {
    //     return (


    //     )
    // }

    //main view
    return (
        <>
            <Helmet>
                <title>Assessment Report</title>
            </Helmet>
            <Box className={classes.container} >
                <Box p={3}>
                    <Button color="primary" width={200} onClick={() => navigate('/app/mypanel', { replace: true })}>
                        Back
                    </Button>
                </Box>
                <Box className={classes.centered} mb={-6}>
                    <ButtonGroup variant="text" aria-label="text button group">
                        <LoadingButton
                            loading={mainLoading && !isGraphicLoading}
                            // color='secondary'
                            variant="contained"
                            onClick={() => printImagePDF(`VIA-${assessmentType === 'VIA Audit' ? 'Audit' : 'Biz'}-Main-Report-${companyName}-${projectName}-${_dateAssessed}.pdf`, 'mainreport-')}>
                            Download Main Report
                        </LoadingButton>
                        <LoadingButton
                            loading={execLoading}
                            // color='secondary'
                            variant="outlined"
                            onClick={() => buildExecutiveReport()}>
                            Download Executive Report
                        </LoadingButton>
                        <LoadingButton
                            loading={notesLoading}
                            // color='secondary'
                            variant="outlined"
                            onClick={() => buildNotesReport()}>
                            Download Notes Report
                        </LoadingButton>
                    </ButtonGroup>
                </Box>

                {/* Printable Report */}
                <Container>

                    {mainReport.map((page, index) => (
                        <Box id={`mainreport-${index}`} key={`mainReport-${index}`}>
                            <PageLayout pageNumber={index + 1} hideFooter={hideFooter}>
                                {page}
                            </PageLayout>
                        </Box>
                    ))}

                </Container>
            </Box>
            <ReportLoading />
        </>
    );
}

const useStyles = makeStyles({
    container: {
        //backgroundColor: '#DFE7F6',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'flex-start',
        overflow: 'scroll',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%'
    }
});

const mapStateToProps = state => ({
    reportContent: state.adminReports.reports, //fetch report content on open
    finalEvaluation: state.takingAssessment.finalEvaluation, //fetch evaluation on open
    axisSequence: state.takingAssessment.axisSequence,
    assessorData: state.takingAssessment.assessorData,
    isGraphicLoading: state.takingAssessment.isGraphicLoading,
    dateAssessed: state.takingAssessment.dateAssessed,
    assessmentType: state.takingAssessment.type
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps)(DownloadReport);
